/*
 * @Author: lazyzzd
 * @Date: 2021-12-26 18:55:57
 * @LastEditors: lazyzzd
 * @LastEditTime: 2022-07-01 20:15:43
 * @Description: file content
 */
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "normalize.css";
import "element-plus/dist/index.css";
import "./style/common.scss";
import ElementPlus from "element-plus";
import zhCn from "element-plus/es/locale/lang/zh-cn";

createApp(App)
  .use(store)
  .use(router)
  .use(ElementPlus, {
    locale: zhCn,
  })
  .mount("#app");
