/*
 * @Author: lazyzzd
 * @Date: 2021-12-26 18:55:57
 * @LastEditors: lazyzzd
 * @LastEditTime: 2022-07-07 21:15:35
 * @Description: file content
 */
import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import Container from "@/layout/Container.vue";

export const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: Container,
    redirect: "/home",
    children: [
      {
        path: "/home",
        component: () => import("../views/Order/index.vue"),
        meta: {
          title: "订单管理",
        },
      },
      {
        path: "/user",
        component: () => import("../views/User/index.vue"),
        meta: {
          title: "用户管理",
        },
      },
      {
        path: "/status",
        component: () => import("../views/Status/index.vue"),
        meta: {
          title: "状态管理",
        },
      },
    ],
  },
  {
    path: "/login",
    component: () => import("../views/Login/index.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
